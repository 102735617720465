import React from "react";
import Image from "../image/image.js";
import InfoSlider from "../infoSlider/infoSlider.js";
import PurpleArrow from "../../assets/img/svg/purple-arrow.svg";

import "./style.scss";
import ReadMore from "../readMore/readMore";

const InfoCard = ({ title, body, advantages, filename, alt, index, infoSlides, button = false }) => {

  return (
    <div className="infoCard">
      {index % 2 !== 0 ? (
        <div className="container">
          <div className="columns">
            <div className="column is-5">
              {!infoSlides && (
                <div className="infoCardText">
                  <span>{!infoSlides && title}</span>
                  <br />
                  <br />
                  {advantages &&
                    advantages.map((i, index) => (
                      <div className="advantages" key={index}>
                        <img src={PurpleArrow} alt="" />
                        &nbsp;&nbsp;{i.text}
                        <br />
                      </div>
                    ))}
                  <br />
                  {body}
                  {button &&
                    <ReadMore to={button} text="Scopri di più" color="white"  externalLink={false}/>
                  }
                </div>
              )}
              {infoSlides && (
                <InfoSlider
                  staticTitle={title}
                  infoSlides={infoSlides}
                />
              )}
              <br />
            </div>
            <div className="column is-7">
              <div className="infoCardImageContainer">
                <div className="infoCardImage">
                  <Image filename="diamond-1.png" alt={alt} />
                </div>
                <div className="infoCardImage floatA">
                  <Image filename="ball.png" alt={alt} />
                </div>
                <div className="infoCardImage floatB">
                  <Image filename="cube.png" alt={alt} />
                </div>
                <div className="infoCardImage floatC">
                  <Image filename="star.png" alt={alt} />
                </div>
                <div className="infoCardImage floatD">
                  <Image filename={filename} alt={alt} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="columns invert">
            <div className="column is-7">
              <div className="infoCardImageContainer left">
                <div className="infoCardImage">
                  <Image filename="diamond-2.png" alt={alt} />
                </div>
                <div className="infoCardImage floatE">
                  <Image filename="ball.png" alt={alt} />
                </div>
                <div className="infoCardImage floatF">
                  <Image filename="cube.png" alt={alt} />
                </div>
                <div className="infoCardImage floatG">
                  <Image filename="star.png" alt={alt} />
                </div>
                <div className="infoCardImage floatH">
                  <Image filename={filename} alt={alt} />
                </div>
              </div>
            </div>
            <div className="column is-5">
              <div className="infoCardText">
                <span>{!infoSlides && title}</span>
                <br />
                <br />
                {advantages &&
                  advantages.map((i, index) => (
                    <div className="advantages" key={index}>
                      <img src={PurpleArrow} alt="" />
                      &nbsp;&nbsp;{i.text}
                      <br />
                    </div>
                  ))}
                {body}
                {button &&
                    <ReadMore to={button} text="Scopri di più" color="white"  externalLink={false}/>
                }              </div>
              {infoSlides && (
                <InfoSlider
                  staticTitle={title}
                  infoSlides={infoSlides}
                />
              )}
              <br />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InfoCard;
