import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";

import "./style.scss";

const InfoSlider = ({ infoSlides, staticTitle }) => {
  return (
    <>
      <div className="container infoSlider">
        <div className="columns">
          {staticTitle && (
            <p className="title">
              {staticTitle}
              <br />
              <br />
            </p>
          )}
          <Swiper
            modules={[Pagination, Autoplay]}
            spaceBetween={30}
            slidesPerView={1}
            loop={true}
            grabCursor={true}
            speed={4000}
            autoplay={{ delay: 1000, disableOnInteraction: false }}
            pagination={{
              clickable: true,
            }}
            className="mySwiper"
          >
            {infoSlides.map((i, index) => (
              <SwiperSlide key={index}>
                <p>{i.body}</p>
              </SwiperSlide>
            ))}
          </Swiper>
          {infoSlides.map((i, index) => (
            <p className="infoMobile" key={index}>
              <b>{index + 1}&#46;</b>&nbsp;{i.body}
            </p>
          ))}
        </div>
      </div>
    </>
  );
};

export default InfoSlider;
