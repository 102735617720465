import React from "react";
import Header from "../components/header/header";
import InfoCard from "../components/infoCard/infoCard.js";

const PageProps = {
  title: "Home",
  tagline: "Gestisci i tuoi clienti e migliora la tua reputazione online",
  metaDescription: "Migliora la tua reputazione online",
  banner: "balloons.png",
  alt: "Logo Eucleia",
  to: "/",
};

const INFOCARDS = [
  {
    title:
      "Eucleia è un software in cloud che consente di migliorare la tua reputazione online e di abbattere di oltre il 70% le recensioni negative in modo semplice.",
    body: "Lo sapevi che la soddisfazione del tuo cliente genera pubblicità? Mission della tua attività: un cliente soddisfatto!",
    filename: "smartphone.png",
    alt: "Smartphone",
  },
  {
    title: "Previeni le recensioni negative",
    body: "",
    filename: "bell.png",
    alt: "Bell",
    infoSlides: [
      {
        body: `Monitorizzazione delle eventuali problematiche del During Stay`,
      },
      {
        body: `Cliente soddisfatto`,
      },
      {
        body: `Recensione positiva`,
      },
      {
        body: `Aumento brand reputation`,
      },
    ],
  },
  {
    title: "La potenza dell'Intelligenza Artificiale",
    body: "Il nostro modulo Whatsapp Chatbot, grazie all’Intelligenza Artificiale, è in grado di rispondere in autonomia alle domande più frequenti dei tuoi clienti, fornendo loro informazioni utili e risolvendo i loro dubbi. In questo modo, il tuo staff potrà dedicarsi ad attività più importanti e strategiche.",
    filename: "waxia.png",
    button: "/whatsapp-ai",
    alt: "Chat",
  },
  {
    title: "Fidelizza i tuoi clienti",
    body: "Offri ai tuoi clienti un’assistenza personalizzata nel corso della permanenza grazie al nostro sistema di messaggistica. Crea e mantieni un rapporto privilegiato con i tuoi clienti, ringraziali per aver scelto la tua attività, contattali periodicamente tramite newsletter, invia offerte personalizzate e auguri per festività o ricorrenze.",
    filename: "chat.png",
    alt: "Chat",
  },
  {
    title: "Il ruolo delle recensioni",
    body: "Scala le classifiche dei principali customers reviews online e incrementa i tuoi affari!",
    filename: "idea.png",
    alt: "Idea",
    advantages: [
      {
        text: "Il 93% dei viaggiatori afferma che le recensioni hanno un effetto fondamentale nella loro decisione in fase di prenotazioni in hotel.",
      },
      {
        text: "Il 79% ha rinunciato ad un acquisto online a causa dei commenti negativi.",
      },
    ],
  },
];

const IndexPage = () => {
  return (
    <>
      <Header {...PageProps} />
      {INFOCARDS.map((i, index) => (
        <InfoCard {...i} index={index} key={index} />
      ))}
    </>
  );
};

export default IndexPage;
